<template>
    <div class="medialist">
        <a-page-header
            :title="$t('orders.edit.title')"
            class="header_title"
            @back="() => $router.go(-1)"
        />
        <a-spin :spinning="spinning">
        <!-- <div class="box_content"> -->
          <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 17 }" @submit="handleSubmit">
              <a-form-item :label="$t('orders.edit.item_1')">
                <a-date-picker style='width:100%' v-decorator="['order_date',  { rules: [{ required: true, message: $t('orders.add.alert_msg')  }]}]"/>
              </a-form-item>

              <a-form-item :label="$t('orders.edit.item_2')">
                <!-- <a-input
                    placeholder=""
                    v-decorator="[
                        'product_code',
                        { rules: [{ required: true, message: $t('orders.add.alert_msg')  }] },
                    ]"
                /> -->
                <a-select style="width: 100%" v-decorator="[
                      'product_code',
                      { rules: [{ required: true, message: $t('orders.add.alert_msg')  }] },
                  ]" >
                  <a-select-option v-for="d in data" :key="d.sku">
                    {{ d.sku }}
                  </a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item :label="$t('orders.edit.item_3')">
                <a-input
                    placeholder=""
                    v-decorator="[
                        'order_number',
                        { rules: [{ required: false, message: $t('orders.add.alert_msg')  }] },
                    ]"
                />
              </a-form-item>

              <a-form-item :label="$t('orders.edit.item_4')">
                <a-input
                    placeholder=""
                    v-decorator="[
                        'product_model',
                        { rules: [{ required: false, message: $t('orders.add.alert_msg')}] },
                    ]"
                />
              </a-form-item>

              <a-form-item :label="$t('orders.edit.item_5')">
                <a-input
                    placeholder=""
                    v-decorator="[
                        'country',
                        { rules: [{ required: false, message: $t('orders.add.alert_msg') }] },
                    ]"
                />
              </a-form-item>

              <a-form-item :label="$t('orders.edit.item_6')">
                <a-input
                    placeholder=""
                    v-decorator="[
                        'addressee',
                        { rules: [{ required: false, message: $t('orders.add.alert_msg')  }] },
                    ]"
                />
              </a-form-item>

              <a-form-item :label="$t('orders.edit.item_7')">
                <a-input
                    placeholder=""
                    v-decorator="[
                        'recipient_address',
                        { rules: [{ required: false, message: $t('orders.add.alert_msg')  }] },
                    ]"
                />
              </a-form-item>

              <a-form-item :label="$t('orders.edit.item_8')">
                <a-input
                    placeholder=""
                    v-decorator="[
                        'telephone_number',
                        { rules: [{ required: false, message: $t('orders.add.alert_msg')  }] },
                    ]"
                />
              </a-form-item>

              <a-form-item :label="$t('orders.edit.item_9')">
                <a-input
                    placeholder=""
                    v-decorator="[
                        'postal_code',
                        { rules: [{ required: false, message: $t('orders.add.alert_msg')  }] },
                    ]"
                />
              </a-form-item>

              <a-form-item :label="$t('orders.edit.item_10')">
                <a-input
                    placeholder=""
                    v-decorator="[
                        'quantity',
                        { rules: [{ required: false, message: $t('orders.add.alert_msg')  }] },
                    ]"
                />
              </a-form-item>

              <a-form-item :label="$t('orders.edit.item_11')">
                <!-- <a-input
                    placeholder=""
                    v-decorator="[
                        'shipped_date',
                        { rules: [{ required: false, message: $t('orders.add.alert_msg')  }] },
                    ]"
                /> -->
                <a-date-picker style='width:100%' v-decorator="['shipped_date',  { rules: [{ required: true, message: $t('orders.add.alert_msg')  }]}]"/>
              </a-form-item>

              <a-form-item :label="$t('orders.edit.item_12')">
                <a-input
                    placeholder=""
                    v-decorator="[
                        'service_provider',
                        { rules: [{ required: false, message: $t('orders.add.alert_msg')  }] },
                    ]"
                />
              </a-form-item>

              <a-form-item :label="$t('orders.edit.item_13')">
                <a-input
                    placeholder=""
                    v-decorator="[
                        'logistics_tracking_number',
                        { rules: [{ required: false, message: $t('orders.add.alert_msg')  }] },
                    ]"
                />
              </a-form-item>

              <a-form-item :label="$t('orders.edit.item_14')">
                <a-upload
                  name="file"
                  :multiple="true"
                  :headers="headers"
                  :default-file-list="defaultFileList"
                  action="https://shady.ecomgear.cn/kenneth_testing/maker/admin/index.php/upload"
                  @change="handleChange"
                >
                  <a-button v-if="isShow"> <a-icon type="upload" /> {{$t('orders.edit.btn_upload')}} </a-button>
                </a-upload>
              </a-form-item>
              
              <a-form-item align="center" :wrapper-col="{ span: 12, offset: 5 }">
                <a-button type="primary" html-type="submit">
                    {{$t('orders.edit.btn_submit')}}
                </a-button>
              </a-form-item>

            </a-form>
        <!-- </div> -->
        </a-spin>
    </div>
</template>

<script>
import {
  getSKU,
  add,
  getDetail,
  update
} from "@/network/order3";

export default {
  name: 'NodeprojectMedia',
  components: {  },
  directives: {  },
  data() {
    return {
      data:[],
      spinning:false,
      formLayout: 'horizontal',
      form: this.$form.createForm(this,{name:'coordinated'}),
      headers: {
        'X-Requested-With': null,
      },
      defaultFileList: [],
      path:'',
      isShow:false,
    };
  },
  created() {
    this.getSKU();
  },
  mounted() {
    
  },
  methods: {
    handleChange(info) {
      // console.log(info);
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
        console.log(info.file.status);
      }
      if (info.file.status === 'removed') {
        this.isShow = true;
        this.path = '';
        this.defaultFileList = [];
      }else if (info.file.status === 'done') {
        if(info.file.response.code == 0){
          this.isShow = false;
          this.path = info.file.response.path;
        }
        this.$message.success('file uploaded successfully');
      } else if (info.file.status === 'error') {
        this.$message.error('file upload failed');
      }
    },
    getDetail(order_id){
      getDetail(order_id)
      .then(res=>{
          if(res.code==0){
          let data=res.data;
          console.log(data);
          this.form.setFieldsValue({
            order_date:data.order_date,
            product_code:data.product_code,
            order_number:data.order_number,
            product_model:data.product_model,
            country:data.country,
            addressee:data.addressee,
            recipient_address:data.recipient_address,
            telephone_number:data.telephone_number,
            postal_code:data.postal_code,
            quantity:data.quantity,
            shipped_date:data.shipped_date,
            service_provider:data.service_provider,
            logistics_tracking_number:data.logistics_tracking_number,
          });
          if(data.dow_image){
            this.defaultFileList.push({
              uid: '1',
              name: data.dow_image,
              status: 'done',
              response: 'success',
              url: 'https://shady.ecomgear.cn/kenneth_testing/maker/admin'+data.dow_image,
            });
            this.path = data.dow_image;
            this.isShow = false;
          }else{
            this.isShow = true;
          }
      }});
    },
    getSKU(){
      getSKU().then(res=>{
        if(res.code==0){
          this.data = res.data;
          this.getDetail(this.$route.query.order_id);
        }
      });
    },
    handleSubmit(e){
      e.preventDefault();
      this.form.validateFields((err, values) => {
        let fieldsValue = values;
        if (!err) {
          const values = {
          ...fieldsValue,
          'order_date': ((fieldsValue['order_date'].constructor === Object)?fieldsValue['order_date'].format('YYYY-MM-DD'):fieldsValue['order_date']),
          'shipped_date': ((fieldsValue['shipped_date'].constructor === Object)?fieldsValue['shipped_date'].format('YYYY-MM-DD'):fieldsValue['shipped_date']),
          'dow_image': this.path,
          'order_id':this.$route.query.order_id,
          }
          update(values).then(res=>{
              if(res.code==0){
              this.$message.success("success!");
              this.$router.back(-1)
                  return ;
              }
              this.$message.success(res.msg);
          });
        }

      });
    },
  },
};
</script>

<style  scoped>

</style>